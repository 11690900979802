import { Fragment, useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import Text from "@mobi/ds/components/Text"

const useFaq = (faqs) => {
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:PerguntasFrequentes" }
    },
    question0: {},
    question1: {},
    question2: {},
    question3: {},
    question4: {},
    question5: {},
    question6: {},
    question7: {},
    question8: {},
    question9: {},
    question10: {},
    link: {}
  }

  const handleClickTrack = (index) => {
    trackEvent(trackingInfo[`question${index}`])
  }

  const trackLinkClick = () => {
    trackEvent(trackingInfo.link)
  }

  const items = faqs.map(({ question, answer }, i) => {
    const index = Math.floor(Math.random() * 10000)
    const children = (
      <Fragment key={index}>
        {answer.map((content, textIndex) => (
          <Text key={textIndex} as="p" className="faq__text">
            {content.text}
          </Text>
        ))}
      </Fragment>
    )

    return {
      title: question,
      children,
      index: index + 1,
      onOpen: () => handleClickTrack(i)
    }
  })

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#faq",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  return {
    items,
    trackLinkClick
  }
}

export default useFaq
