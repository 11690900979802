import { Icon } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

const Card = ({ title = "", content = "", icon = null }) => (
  <div className="card">
    <If
      condition={icon}
      renderIf={<Icon icon={icon} className="card__icon" />}
    />
    <Text as="h3" className="card__title">
      {title}
    </Text>
    <Text as="p" className="card__content">
      {content}
    </Text>
  </div>
)

export default Card
