import { useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

const useAccompanyProposal = () => {
  const trackingInfo = {
    pageview: {
      ga: {
        label: "IT:NL:NCC:CredImob:Inicio:JaTemUmFinanciamentoImobiliario"
      }
    },
    event: {
      acompanharProposta: {
        ga: { label: "BTN:Inicio:AcompanheSuaProposta", action: "Inicio" }
      },
      acompanharFinanciamento: {
        ga: {
          label: "BTN:Inicio:AcompanheSeuFinanciamento",
          action: "Inicio"
        }
      }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#accompany-proposal",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  const trackClick = (eventAnalytic) => {
    trackEvent(trackingInfo.event[eventAnalytic])
  }

  return {
    trackClick
  }
}

export default useAccompanyProposal
