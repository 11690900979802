const cards = [
  {
    icon: "pdf",
    title: "biblioteca de documentos",
    track: "bibliotecaDocumentos",
    buttonLink:
      "https://www.itau.com.br/media/dam/m/480a14680b422322/original/IMOB_Biblioteca_De_Documento_IA.pdf",
    buttonText: "acesse aqui"
  },
  {
    icon: "pdf",
    title: "formulário de contratação",
    track: "formulario",
    buttonLink:
      "https://www.itau.com.br/media/dam/m/6e350c44c9821284/original/Itau_Formulario-de-Contratacao-de-Credito-Imobiliario.pdf",
    buttonText: "acesse o formulário"
  }
]

export { cards }
