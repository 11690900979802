import { useEffect } from "react"

import { addTrackPageViewOnScroll } from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

const useMigration = () => {
  const isMobile = useMediaQuery("mobile")

  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:MigracaoEntreLinhasCredito" }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#migration",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  return {
    isMobile
  }
}

export default useMigration
