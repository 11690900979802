const cards = [
  {
    title: "acompanhe a sua\nproposta",
    track: "acompanharProposta",
    subText:
      "Quer acompanhar o andamento da sua proposta e os próximos passos da sua contratação?",
    cardClass: "accompanyProposal__card--neutral",
    component: "accompanyProposal",
    buttonLink:
      "https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/acompanhar-proposta",
    buttonText: "acompanhar sua proposta",
    buttonClass: "accompanyProposal__button--primary"
  },
  {
    title: "já tem um financiamento imobiliário?",
    track: "acompanharFinanciamento",
    subText:
      "Consulte informações do seu contrato, simule amortizações e acompanhe seu financiamento.",
    cardClass: "accompanyProposal__card--primary",
    component: "accompanyProposal",
    buttonLink: "https://www.itau.com.br/servicos/mais-acessos/",
    buttonText: "acompanhar financiamento",
    buttonClass: "accompanyProposal__button--neutral"
  }
]

export { cards }
