const creditsType = [
  {
    title: "crédito imobiliário",
    bigText: [
      {
        text: "Taxa a partir de",
        finalText: "",
        valueText: "10,49%",
        orangeText: "",
        valueComplementary: "a.a. + TR"
      }
    ],
    features: [
      {
        text: "Taxa pré-fixada"
      },
      {
        text: "Taxa fixa a partir de 10,49%  ao ano + TR"
      },
      {
        text: "Não possui parte variável na taxa"
      }
    ],
    orangeText: "diferencial:",
    description:
      "É a linha de crédito imobiliário para você conquistar seu imóvel onde você sabe a taxa e o valor das suas parcelas do início ao fim do financiamento."
  },
  {
    title: "crédito imobiliário juros poupança",
    bigText: [
      {
        text: "Taxa a partir de",
        finalText: "Totalizando 11,66% ao ano + TR",
        valueText: "5,49%",
        orangeText: "+ rendimento da poupança",
        valueComplementary: "ao ano"
      }
    ],
    features: [
      {
        text: "Taxa pós fixada"
      },
      {
        text: "Taxa fixa a partir de 5,49% ao ano"
      },
      {
        text: "Com parte variável igual ao rendimento da poupança"
      },
      {
        text: "Taxa total (parte fixa + variável)  = 11,66% ao ano"
      }
    ],
    orangeText: "diferencial:",
    description:
      "A sua taxa varia de acordo com o rendimento da poupança. Você economiza quando a poupança está baixa e tem um teto caso ela suba. "
  }
]

export { creditsType }
