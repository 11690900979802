import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import useContractingChannels from "./hooks"

import "./style.scss"

import { slides } from "./data"

const ContractingChannels = () => {
  const { trackClick } = useContractingChannels()

  return (
    <section className="contractingChannels" id="contracting-channels">
      <div className="innerContainer contractingChannels__container">
        <Text as="h2" className="contractingChannels__title">
          onde contratar seu crédito imobiliário
        </Text>

        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul
              className="glide__slides contractingChannels__slides"
              aria-hidden="false"
              aria-label="Lista com canais para realizar a contratação do seu crédito imobiliário"
            >
              {slides.map((slide) => (
                <li
                  key={slide.title}
                  className="glide__slide contractingChannels__card"
                >
                  <Img
                    file={slide.image}
                    className="contractingChannels__image"
                  />
                  <div className="contractingChannels__cardBody">
                    <Text as="h3" className="contractingChannels__cardTitle">
                      {slide.title}
                    </Text>
                    {slide.richText(() => trackClick(slide.track))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContractingChannels
