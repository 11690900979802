import { useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

const useVideo = () => {
  const isMobile = useMediaQuery("mobile")
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:Video" }
    },
    event: {
      ga: { label: "BTN:Inicio:AssistaJa", action: "Inicio" }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#video",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  const trackClick = () => {
    trackEvent(trackingInfo.event)
  }

  return {
    trackClick,
    isMobile
  }
}

export default useVideo
