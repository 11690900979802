import Accordion from "@mobi/ds/components/Accordion"

import "./style.scss"

import { items } from "./data"

const PreFaq = () => {
  return (
    <section className="preFaq" id="preFaq">
      <div className="innerContainer preFaq__container">
        <div
          className="preFaq__list"
          aria-hidden="false"
          role="navigation"
          aria-label="Lista com perguntas sobre as taxas do crédito imobiliário"
        >
          <Accordion items={items} oneAtATime />
        </div>
      </div>
    </section>
  )
}

export default PreFaq
