import AriaLabel from "@mobi/components/AriaLabel"
import Text from "@mobi/ds/components/Text"

const slides = [
  {
    image: "card-01",
    title: "simule em nossas plataformas online",
    track: "simule",
    richText: (
      linkCallback = () => {
        return
      }
    ) => (
      <AriaLabel ariaText="Simule  e envie sua proposta através do nosso simulador. Acompanhe pelo aplicativo ou internet banking.">
        <Text as="p" className="contractingChannels__cardContent">
          <a
            className="contractingChannels__link"
            href="https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/simulador/"
            target="_blank"
            rel="noreferrer"
            aria-label="Simule seu crédito imobiliário"
            onClick={linkCallback}
          >
            Simule
          </a>
          <span aria-hidden="true">
            {" "}
            e envie sua proposta através do nosso simulador. Acompanhe pelo
            aplicativo ou internet banking.{" "}
          </span>
        </Text>
      </AriaLabel>
    )
  },
  {
    image: "card-02",
    title: "encontre a agência mais próxima de você",
    track: "maisProximo",
    richText: (
      linkCallback = () => {
        return
      }
    ) => (
      <AriaLabel ariaText="Localize o Itaú  mais próximo  para simular e contratar seu financiamento">
        <Text as="p" className="contractingChannels__cardContent">
          <span aria-hidden="true">Localize o Itaú </span>
          <a
            className="contractingChannels__link"
            href="https://www.itau.com.br/mapa-de-agencias/"
            target="_blank"
            rel="noreferrer"
            aria-label="Localize o Itaú mais próximo"
            onClick={linkCallback}
          >
            mais próximo
          </a>
          <span aria-hidden="true">
            {" "}
            para simular e contratar seu financiamento{" "}
          </span>
        </Text>
      </AriaLabel>
    )
  },
  {
    image: "card-03",
    title: "encontre um de nossos parceiros",
    track: "imobiliariaParceira",
    richText: (
      linkCallback = () => {
        return
      }
    ) => (
      <AriaLabel ariaText="Visite uma  imobiliária parceira , correspondente bancário ou mesmo uma construtoria parceira com obras financiadas pelo Itaú">
        <Text as="p" className="contractingChannels__cardContent">
          <span aria-hidden="true">Visite uma </span>
          <a
            className="contractingChannels__link"
            href="https://www.itau.com.br/correspondentes-no-pais/"
            target="_blank"
            rel="noreferrer"
            aria-label="Busque uma imobiliária parceira"
            onClick={linkCallback}
          >
            imobiliária parceira
          </a>
          <span aria-hidden="true">
            , correspondente bancário ou mesmo uma construtoria parceira com
            obras financiadas pelo Itaú
          </span>
        </Text>
      </AriaLabel>
    )
  }
]

export { slides }
