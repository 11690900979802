import { useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

const useQuote = () => {
  const isMobile = useMediaQuery("mobile")
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:Documentos" }
    },
    event: {
      bibliotecaDocumentos: {
        ga: {
          label: "BTN:Inicio:AcessarBibliotecaDocumentos",
          action: "Inicio"
        }
      },
      formulario: {
        ga: {
          label: "BTN:Inicio:AcessarFormularioContratacao",
          action: "Inicio"
        }
      }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#quote",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  const trackClick = (eventAnalytic) => {
    trackEvent(trackingInfo.event[eventAnalytic])
  }

  return {
    isMobile,
    trackClick
  }
}

export default useQuote
