import Button from "@mobi/ds/components/Button"

import Card from "pages/Home/components/common/Card"
import CardBig from "pages/Home/components/common/CardBig"

import linksEnum from "pages/Home/constants/linksEnum"

import useEasines from "./hooks"

import "./style.scss"

import { cards } from "./data"

const Easines = () => {
  const { trackClick } = useEasines()

  return (
    <section className="innerContainer easines" id="easines">
      <div className="easines__infoWrapper">
        <CardBig
          title="facilidades para contratar seu crédito imobiliário"
          content="Confira as vantagens do financiamento imobiliário do Itaú."
        />
        <Button
          className="easines__button"
          rel="noreferrer"
          onClick={trackClick}
          data-aos="fade-in"
          data-aos-delay="250"
          aria-label="simule agora"
        >
          simule agora
        </Button>
      </div>

      <div className="easines__grid" data-aos="fade-left">
        {cards.map(({ title, text, icon }, index) => (
          <Card key={index} icon={icon} title={title} content={text} />
        ))}
      </div>
    </section>
  )
}

export default Easines
