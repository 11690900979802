import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import useAccompanyProposal from "./hooks"

import "./style.scss"

import { cards } from "./data"

const AccompanyProposal = () => {
  const { trackClick } = useAccompanyProposal()

  return (
    <section className="accompanyProposal" id="accompany-proposal">
      <div className="innerContainer accompanyProposal__container">
        {cards.map(
          (
            {
              title,
              subText,
              buttonText,
              buttonLink,
              cardClass,
              buttonClass,
              track
            },
            index
          ) => (
            <div
              key={index}
              className={cardClass}
              data-aos="fade-in"
              data-aos-delay={index * 150}
            >
              <Text as="h2">{title}</Text>
              <Text as="p" weight="400">
                {subText}
              </Text>
              <Button
                className={buttonClass}
                tagName="a"
                href={buttonLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => trackClick(track)}
              >
                {buttonText}
              </Button>
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default AccompanyProposal
