import { useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

const useHomeInsurance = () => {
  const isMobile = useMediaQuery("mobile")
  const trackingInfo = {
    pageview: {},
    event: {}
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#homeinsurance",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  const trackClick = () => {
    trackEvent(trackingInfo.event)
  }

  return {
    isMobile,
    trackClick
  }
}

export default useHomeInsurance
