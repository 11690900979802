import AriaLabel from "@mobi/components/AriaLabel"
import Accordion from "@mobi/ds/components/Accordion"
import Text from "@mobi/ds/components/Text"

import useFaq from "./hooks"

import "./style.scss"

import { faqs } from "./data"

const Faq = () => {
  const { items, trackLinkClick } = useFaq(faqs)
  return (
    <section className="faq" id="faq">
      <div className="innerContainer faq__container">
        <Text as="h2" className="faq__title">
          perguntas frequentes
        </Text>
        <div className="faq__list" aria-hidden="false">
          <Accordion items={items} oneAtATime />
        </div>
        <AriaLabel ariaText="Para mais perguntas, acesse aqui">
          <Text as="p" className="faq__info">
            <span aria-hidden="true">Para mais perguntas, </span>
            <a
              className="faq__link"
              href="https://www.itau.com.br/atendimento-itau/para-voce/credito-imobiliario/"
              target="_blank"
              rel="noreferrer"
              aria-label="acesse aqui"
              onClick={trackLinkClick}
            >
              acesse aqui
            </a>
          </Text>
        </AriaLabel>
        <Text as="p" className="faq__legalText">
          A contratação do Crédito Imobiliário está sujeita à análise e
          aprovação de crédito pelo Itaú. Consulte sempre as condições
          previamente a contratação. A taxa mínima de juros a partir de 10,49%
          ao ano + TR para crédito imobiliário. E para crédito imobiliário juros
          poupança é de 5,49% ao ano + variação do índice da poupança.
        </Text>
      </div>
    </section>
  )
}

export default Faq
