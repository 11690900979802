import Button from "@mobi/ds/components/Button"

import InfoCard from "./components/InfoCard"
import CardBig from "pages/Home/components/common/CardBig"

import linksEnum from "pages/Home/constants/linksEnum"

import useModalities from "./hooks"

import "./style.scss"

import { creditsType } from "./data"

const Modalities = () => {
  const { isMobile, trackClick } = useModalities()

  return (
    <section className="modalities" id="modalities">
      <div className="innerContainer modalities__container">
        <div className="modalities__infoWrapper">
          <CardBig
            title="conheça as modalidades de Crédito Imobiliário com as melhores taxas para você"
            className="modalities__cardBig"
          />
          <Button
            className="modalities__button"
            tagName="a"
            href={linksEnum.SIMULE_AGORA}
            target="_self"
            rel="noreferrer"
            onClick={trackClick}
            data-aos="fade-in"
            data-aos-delay="250"
            aria-label="simule agora"
          >
            simule agora
          </Button>
        </div>
        <ul
          className="modalities__infoCardList"
          aria-hidden="false"
          aria-label="Lista com detalhes a respeito das duas modalidades de crédito imobiliário"
        >
          {creditsType.map(
            ({ title, bigText, orangeText, description, features }, index) => (
              <li
                key={index}
                data-aos="fade-in"
                data-aos-delay={isMobile ? "0" : index * 200}
                className="modalities__listItem"
              >
                <InfoCard
                  key={index}
                  title={title}
                  introText={bigText[0].text}
                  mainText={bigText[0].valueText}
                  mainTextComplement={bigText[0].valueComplementary}
                  subText={bigText[0].orangeText}
                  infoText={bigText[0].finalText}
                  orangeText={orangeText}
                  diferential={description}
                  listItems={features}
                  mainTextClass={"modalities__infoCardMainText"}
                  data-aos="fade-up"
                />
              </li>
            )
          )}
        </ul>
      </div>
    </section>
  )
}

export default Modalities
