import Button from "@mobi/ds/components/Button"
import Input from "@mobi/ds/components/Input"
import Text from "@mobi/ds/components/Text"

import useHeroForm from "./hooks"

import "./style.scss"

const HeroForm = () => {
  const { onSubmitCallback, handleSubmit, control, isValid, isLoading } =
    useHeroForm()

  return (
    <div className="hero-form">
      <Text as="h2" className="hero-form__title">
        simule o financiamento do seu novo imóvel
      </Text>

      <form onSubmit={handleSubmit(onSubmitCallback)}>
        <Input.Cpf
          autoComplete="off"
          label="cpf"
          name="cpf"
          data-testid="home_cpf"
          control={control}
          className="hero-form__input"
          placeholder="Digite seu CPF"
        />
        <Button
          fluid
          isLoading={isLoading}
          className="hero-form__button"
          data-testid="home_submit_cpf"
          type="submit"
          value="simule agora"
          disabled={!isValid}
          aria-label="simule agora"
        >
          simule agora
        </Button>
      </form>
    </div>
  )
}

export default HeroForm
