import { useState } from "react"
import { useForm } from "react-hook-form"

import { navigate } from "gatsby"

import { httpRequests, proposalHttpController } from "@garantidos/services"
import {
  clearStorage,
  formatters,
  setCpfInStorage,
  setItem
} from "@garantidos/utils"
import { trackEvent } from "@garantidos/utils/tracking"

import linksEnum from "pages/Home/constants/linksEnum"

const useHeroForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { control, handleSubmit, formState } = useForm({
    mode: "onChange"
  })
  const { isValid } = formState
  const { hashDocument } = httpRequests

  const onSubmitCallback = async (data) => {
    if (isLoading) return
    const { cpf } = data
    if (!cpf) return
    setIsLoading(true)
    const sanitizedCpf = formatters.sendFormatters.cpf({ value: cpf })
    let hashedCpf = ""
    try {
      hashedCpf = await hashDocument(sanitizedCpf)
      const trackingInfo = {
        event: {
          ga: { label: "BTN:Inicio:ColetaCPF", action: "Inicio", hashedCpf }
        }
      }
      trackEvent(trackingInfo.event)
      const payload = { document_number: sanitizedCpf }
      const { a3sLeadUUID, eq3_uuid } = await proposalHttpController({
        method: "prospectDocument",
        payload
      })
      setItem("a3sLead", a3sLeadUUID)
      setItem("eq3_uuid", eq3_uuid)
    } catch (error) {
      console.log(error)
    } finally {
      const ignoreItens = ["appGeoloc", "a3sLead", "eq3_uuid"]
      clearStorage(ignoreItens)
      const urlParams = window.location.search
      setCpfInStorage({ cpf: sanitizedCpf, hashedCpf })
      navigate(`${linksEnum.PROPOSAL_PATHNAME}${urlParams}`)
      setIsLoading(false)
    }
  }
  return { onSubmitCallback, handleSubmit, control, isValid, isLoading }
}

export default useHeroForm
