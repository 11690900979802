const cards = [
  {
    icon: "celular",
    text: "Envie sua proposta e acompanhe o status em nossos canais.",
    title: "contratação online"
  },
  {
    icon: "gerente",
    text: "Atendimento exclusivo e apoio durante toda a contratação.",
    title: "consultores especializados"
  },
  {
    icon: "relogio",
    text: "Retorno em até 1 hora para financiamentos de até R$ 1,5 milhão.",
    title: "resposta em até 1 hora"
  },
  {
    icon: "poupanca",
    text: "Para compor o valor de entrada ou durante o seu contrato.",
    title: "use seu FGTS"
  },
  {
    icon: "calendario",
    text: "Pule até 2 parcelas seguidas do seu financiamento por ano, podendo ser recontratado a cada 12 meses.",
    title: "pula parcela"
  },
  {
    icon: "seguro-residencial",
    text: "Em caso de emergência, segurança pra você e seu imóvel. Esse seguro é obrigatório.",
    title: "seguro habitacional"
  }
]

export { cards }
