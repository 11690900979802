const contractingStepsList = [
  {
    text: "Faça sua simulação e envie sua proposta."
  },
  {
    text: "Receba o retorno de sua proposta em até 1h para financiamentos de até R$ 1,5 milhão."
  },
  {
    text: "Envie seus documentos para análise dos dados e acompanhe sua proposta online."
  },
  {
    text: "Avaliação do imóvel e emissão do contrato para registro em cartório."
  },
  {
    text: "Após registro do contrato, o crédito será liberado em até 10 dias úteis."
  }
]

export { contractingStepsList }
