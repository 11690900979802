import Img from "components/img"

import CardBig from "pages/Home/components/common/CardBig"

import useMigration from "./hooks"

import "./style.scss"

const Migration = () => {
  const { isMobile } = useMigration()

  return (
    <section className="migration" id="migration">
      <div className="migration__container">
        <div className="migration__imageContainer">
          <Img
            file="lp-imobi-4"
            alt="Mulher subindo uma escada"
            className="migration__image"
            data-aos={isMobile ? "fade-left" : "fade-right"}
          />
        </div>
        <div className="migration__infoWrapper">
          <div className="migration__cardContainer">
            <CardBig
              icon="favorito"
              title="migração entre linhas de crédito"
              content="Se você possui Crédito Imobiliário Itaú e deseja migrar para outra linha de crédito, é possível consultar a elegibilidade e taxas aplicáveis através do nosso atendimento pelo telefone 40047051 para capitais. Para migrar entre modalidades, é necessário ter o contrato há mais de 3 meses com os pagamentos em dia. Caso queira migrar de produto novamente só será possível após 1 ano."
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Migration
