import { Icon } from "@garantidos/components"

import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import "./style.scss"

const CardBig = ({ title = "", content = "", icon = "", className = "" }) => (
  <div className={`cardBig ${className}`}>
    <If
      condition={!!icon}
      renderIf={<Icon icon={icon} className="cardBig__icon" />}
    />
    <Text
      as="h2"
      className="cardBig__title"
      data-aos="fade-in"
      data-aos-delay="150"
    >
      {title}
    </Text>
    <If
      condition={!!content}
      renderIf={
        <Text
          as="p"
          className="cardBig__content"
          data-aos="fade-in"
          data-aos-delay="150"
        >
          {content}
        </Text>
      }
    />
  </div>
)

export default CardBig
