import { useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

const useBeAClient = () => {
  const isMobile = useMediaQuery("mobile")
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:SejaUmCliente" }
    },
    event: {
      ga: { label: "BTN:Inicio:AbraSuaConta", action: "Inicio" }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      trackObject: trackingInfo.pageview,
      elementId: "#be-a-client",
      threshold: 0.5
    })
  }, [])

  const trackClick = () => {
    trackEvent(trackingInfo.event)
  }

  return {
    trackClick,
    isMobile
  }
}

export default useBeAClient
