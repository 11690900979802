import { useEffect } from "react"

import { addTrackPageViewOnScroll } from "@garantidos/utils/tracking"

const useHero = () => {
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:Inicio" },
      ga4: {
        customPath: "/landing-page",
        implementationTeam: "imob:v01"
      }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#hero",
      trackObject: trackingInfo.pageview
    })
  }, [])
}

export default useHero
