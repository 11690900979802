import { useEffect } from "react"

import { addTrackPageViewOnScroll } from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import contractingStepsList from "./data"

const useContracting = () => {
  const isMobile = useMediaQuery("mobile")
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:ComoContratar" }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#contracting",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  return {
    isMobile,
    contractingStepsList
  }
}

export default useContracting
