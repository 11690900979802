import { Icon } from "@garantidos/components"

import Text from "@mobi/ds/components/Text"

import "./style.scss"

const DocumentCard = ({
  title,
  icon,
  link,
  linkLabel,
  onClickCallback,
  ...props
}) => (
  <div className="documentCard" {...props}>
    <Icon icon={icon} className="documentCard__icon" />
    <div className="documentCard__content">
      <Text as="h3" className="documentCard__title">
        {title}
      </Text>
      <Text as="p" className="documentCard__label">
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="documentCard__link"
          onClick={onClickCallback}
          aria-label={`Acesse: ${title}`}
        >
          {linkLabel}
        </a>
      </Text>
    </div>
  </div>
)

export default DocumentCard
