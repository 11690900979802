import AriaLabel from "@mobi/components/AriaLabel"
import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"
import IconCheck from "@mobi/ds/static/icons/outlined/check.svg"

import "./style.scss"

const InfoCard = ({
  title = "",
  introText = "",
  mainText = "",
  mainTextComplement = "",
  subText = "",
  infoText = "",
  orangeText = "",
  diferential = "",
  listItems = [],
  mainTextClass = ""
}) => (
  <div className="infoCard">
    <div className="infoCard__title-wrapper">
      <Text as="h3" className="infoCard__title">
        {title}
      </Text>
    </div>
    <div className="infoCard__content">
      <div className="infoCard__main-text-container">
        <AriaLabel
          ariaText={`${introText} ${mainText} ${mainTextComplement} ${subText} ${infoText}`}
        >
          <Text as="p" className="infoCard__label" aria-hidden="true">
            {introText}
          </Text>
          <div className="infoCard__main-textWrapper" aria-hidden="true">
            <Text as="p" weight="800" className={mainTextClass}>
              {mainText}
            </Text>
            <Text as="p" className="infoCard__main-text-complement">
              {mainTextComplement}
            </Text>
          </div>
          <If
            condition={!!subText}
            renderIf={
              <Text as="p" className="infoCard__subtext" aria-hidden="true">
                {subText}
              </Text>
            }
          />
          <If
            condition={!!infoText}
            renderIf={
              <Text as="p" className="infoCard__info-text" aria-hidden="true">
                {infoText}
              </Text>
            }
          />
        </AriaLabel>
      </div>
      <div>
        <Text as="p" className="infoCard__diferential-label">
          {orangeText}
        </Text>
        <Text as="p" className="infoCard__diferential">
          {diferential}
        </Text>
      </div>
      <ul
        className="infoCard__info-list"
        aria-hidden="false"
        aria-label={`Lista com informações sobre a modalidade ${title}`}
      >
        {listItems.map((item, index) => (
          <li key={index}>
            <IconCheck className="infoCard__icon" aria-hidden="true" />
            <Text as="p" className="infoCard__list-item-text">
              {item.text}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default InfoCard

/*

<Text as="p" className="visually-hidden">
  {`Taxa a partir de ${mainText} ${mainTextComplement} ${subText} ${infoText}`}
</Text>

<Text as="p" className="visually-hidden">
  {`diferencial: ${diferential}`}
</Text>

*/
