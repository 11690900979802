import { useEffect } from "react"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import useMediaQuery from "@mobi/hooks/useMediaQuery"

import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"

const useContractingChannels = () => {
  const isMobile = useMediaQuery("mobile")

  useEffect(() => {
    const glide = new Glide(".glide", {
      perView: 1.2
    })
    if (isMobile) glide.mount()
    else glide.destroy()

    return () => {
      if (glide) glide.destroy()
    }
  }, [isMobile])

  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:OndeContratar" }
    },
    event: {
      simule: {
        ga: { label: "BTN:Inicio:Simular", action: "Inicio" }
      },
      maisProximo: {
        ga: { label: "BTN:Inicio:ProcurarAgencia", action: "Inicio" }
      },
      imobiliariaParceira: {
        ga: { label: "BTN:Inicio:VisiteImobiliariaParceiro", action: "Inicio" }
      },
      construtoraParceira: {
        ga: { label: "BTN:Inicio:VisiteConstrutoraParceiro", action: "Inicio" }
      }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#contracting-channels",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  const trackClick = (eventAnalytic) => {
    trackEvent(trackingInfo.event[eventAnalytic])
  }

  return {
    trackClick
  }
}

export default useContractingChannels
