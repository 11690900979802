import If from "@mobi/components/If"
import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import useContracting from "./hooks"

import "./style.scss"

import { contractingStepsList } from "./data"

const Contracting = () => {
  const { isMobile } = useContracting()

  return (
    <section className="contracting" id="contracting">
      <div className="innerContainer" data-aos="fade-up">
        <Text as="h2" className="contracting__title">
          como funciona <br /> o crédito imobiliário:
        </Text>
        <Text as="p" className="contracting__subtitle">
          Conte com nossos especialistas e acompanhe sua proposta online.
        </Text>
      </div>
      <div className="contractingSteps">
        <If
          condition={!isMobile}
          renderIf={
            <div className="contractingSteps__imageContainer">
              <Img
                file="lp-imobi-2"
                alt="Mulher utilizando o celular"
                className="contracting-channels__image"
              />
            </div>
          }
        />
        <div className="contractingSteps__listWrapper">
          <ol
            className="contractingSteps__list"
            aria-hidden="false"
            aria-label="Lista com os cinco passos necessários para realizar a contratação do crédito imobiliário"
          >
            {contractingStepsList.map((step, index) => (
              <li key={index} data-aos="fade-in">
                <Text
                  as="p"
                  className="contractingSteps__num"
                  aria-hidden="true"
                >
                  {index + 1}
                </Text>
                <Text as="p" className="contractingSteps__text">
                  {step.text}
                </Text>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  )
}

export default Contracting
