import { removeLastBackslash } from "@garantidos/utils"

const windowLocation = typeof window !== "undefined" && window.location

const urlParams = typeof window !== "undefined" && windowLocation.search
const startProposalUrl = `${removeLastBackslash(
  windowLocation.origin + windowLocation.pathname
)}/proposta${urlParams || ""}`

const linksEnum = {
  PROPOSAL_PATHNAME: "/proposta",
  SIMULE_AGORA: startProposalUrl,
  CARROSSEL: {
    SIMULE:
      "https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/simulador/",
    MAIS_PROXIMO: "https://www.itau.com.br/mapa-de-agencias/",
    IMOBILIARIA_PARCEIRA: "https://www.itau.com.br/correspondentes-no-pais/",
    CONSTRUTORA_PARCEIRA:
      "https://www.itau.com.br/content/dam/itau/varejo/emprestimos-financiamentos/credito-imobiliario/lista-de-obras.pdf"
  },
  SIMULAR_PORTABILIDADE:
    "https://credito-imobiliario.itau.com.br/portabilidade",
  ACOMPANHAR_PROPOSTA:
    "https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/acompanhar-proposta",
  ACOMPANHAR_FINANCIAMENTO: "https://www.itau.com.br/servicos/mais-acessos/",
  ABRA_SUA_CONTA:
    "https://www.itau.com.br/abra-sua-conta/tp/home/?utm_source=st&utm_medium=nd&utm_campaign=st-conv-nd-st-nd-all&utm_content=nd-nd-nd-iacnta-nd-pfaqu-MT00000830-imoblp&utm_term=",
  ASSISTA_JA:
    "https://www.youtube.com/watch?v=ra7jLO04bTQ&list=PL34w81iXr8Ct0BXkjO4hwFi0ATV2PDn3X&index=1",
  DOCUMENTOS: {
    ACESSE_AQUI:
      "https://www.itau.com.br/content/dam/itau/varejo/credito-imobiliario-biblioteca-de-documentos.pdf",
    ACESSE_FORMULARIO:
      "https://www.itau.com.br/content/dam/itau/varejo/IA_Documentos-e-Formularios-para-a-Contratacao.pdf"
  },
  FAQ: {
    MAIS_PERGUNTAS: {
      CLIQUE_AQUI:
        "https://www.itau.com.br/atendimento-itau/para-voce/credito-imobiliario/"
    }
  }
}

export default linksEnum
