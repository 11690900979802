import Text from "@mobi/ds/components/Text"

import DocumentCard from "./components/DocumentCard"

import useQuote from "./hooks"

import "./style.scss"

import { cards } from "./data"

const Quote = () => {
  const { isMobile, trackClick } = useQuote()

  return (
    <section className="quote" id="quote">
      <div className="innerContainer quote__container">
        <div
          className="quote__infoWrapper"
          data-aos={isMobile ? "fade-in" : "fade-right"}
        >
          <Text as="h2" className="quote__title">
            dúvidas nos <br />
            documentos solicitados?
          </Text>
          <Text as="p" className="quote__subtitle">
            Confira a seguir mais detalhes sobre a documentação necessária:
          </Text>
        </div>
        <div className="quote__documentsWrapper">
          {cards.map(
            ({ title, icon, buttonLink, buttonText, track }, index) => (
              <DocumentCard
                key={index}
                title={title}
                icon={icon}
                link={buttonLink}
                linkLabel={buttonText}
                onClickCallback={() => trackClick(track)}
                data-aos={isMobile ? "fade-in" : "fade-left"}
                data-aos-delay={isMobile ? 0 : index * 200}
              />
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default Quote
