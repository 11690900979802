import Text from "@mobi/ds/components/Text"

const steps = [
  {
    icon: "home",
    richText: (
      <Text as="p" className="homeInsuranceSteps__text">
        Precisa de ajuda para{" "}
        <strong>instalar quadros, espelhos ou mesmo prateleiras?</strong> Temos
        um time que resolve isso pra você.
      </Text>
    )
  },
  {
    icon: "chave",
    richText: (
      <Text as="p" className="homeInsuranceSteps__text">
        Já imaginou{" "}
        <strong>quebrar um piso, gesso e até mesmo estragar uma pintura</strong>{" "}
        durante uma pequena reforma? Seu lar está amparado com essas e outras
        situações inesperadas em casa.
      </Text>
    )
  },
  {
    icon: "calendario",
    richText: (
      <Text as="p" className="homeInsuranceSteps__text">
        Com o seguro residencial você conta com diversos benefícios para
        proteção do seu imóvel com{" "}
        <strong>assistências emergenciais, como chaveiro e eletricista</strong>{" "}
        e coberturas que te amparam em situações em caso de{" "}
        <strong>roubo, vazamentos e danos a outras pessoas.</strong>
      </Text>
    )
  },
  {
    icon: "carteiras-digitais",
    richText: (
      <Text as="p" className="homeInsuranceSteps__text">
        Por <strong>apenas R$39,90 por mês</strong>, seu lar fica{" "}
        <strong>protegido</strong> com diversos benefícios.
      </Text>
    )
  }
]

export { steps }
