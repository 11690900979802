import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"

import Img from "components/img"

import CardBig from "pages/Home/components/common/CardBig"

import useVideo from "./hooks"

import "./style.scss"

const Video = () => {
  const { trackClick, isMobile } = useVideo()

  return (
    <section className="video" id="video">
      <div className="video__container">
        <If
          condition={!isMobile}
          renderIf={
            <div className="video__imageContainer">
              <picture>
                <Img
                  file="lp-imob-06"
                  alt="Mulher e homem sorrindo com a mensagem no centro: Tudo o que você precisa saber sobre o Crédito Imobiliário"
                  className="video__image"
                  data-aos="fade-right"
                  width="100%"
                  height="100%"
                />
              </picture>
            </div>
          }
        />

        <div className="video__infoWrapper">
          <div className="video__cardContainer">
            <CardBig
              icon="video"
              title="preparamos uma série de vídeos com os mitos e verdades do produto"
              content="Tire todas as suas dúvidas"
            />
          </div>
          <If
            condition={isMobile}
            renderIf={
              <div className="video__picture">
                <Img
                  file="lp-imob-06-mobile"
                  alt="Mulher e homem sorrindo com a mensagem no centro: Tudo o que você precisa saber sobre o Crédito Imobiliário"
                  className="video__image"
                  data-aos="fade-right"
                />
              </div>
            }
          />

          <div className="video__buttonContainer">
            <Button
              className="video__button"
              tagName="a"
              href="https://www.youtube.com/watch?v=ra7jLO04bTQ&list=PL34w81iXr8Ct0BXkjO4hwFi0ATV2PDn3X&index=1"
              target="_blank"
              rel="noreferrer"
              onClick={trackClick}
              data-aos="fade-in"
              data-aos-delay="250"
            >
              assista já
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Video
