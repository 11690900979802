import { Icon } from "@garantidos/components"

import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"
import Text from "@mobi/ds/components/Text"

import Img from "components/img"

import useHomeInsurance from "./hooks"

import "./style.scss"

import { steps } from "./data"

const HomeInsurance = () => {
  const { isMobile, trackClick } = useHomeInsurance()

  return (
    <section className="homeInsurance" id="homeinsurance">
      <div className="innerContainer" data-aos="fade-up">
        <Text as="h2" className="homeInsurance__title">
          Seguro Residencial Porto
        </Text>
        <Text as="p" className="homeInsurance__subtitle">
          Um seguro exclusivo da Porto para clientes e futuros clientes do
          financiamento de imóvel Itaú.
        </Text>
      </div>
      <div className="homeInsuranceSteps">
        <If
          condition={!isMobile}
          renderIf={
            <div className="homeInsuranceSteps__imageContainer">
              <Img
                file="lp-imobi-secao-seguro-residencial"
                alt="Homem roupão e com uma xícara de café na mão"
                className="homeInsuranceSteps__image"
                data-aos="fade-right"
              />
            </div>
          }
        />
        <div className="homeInsuranceSteps__listWrapper">
          <ul
            className="homeInsuranceSteps__list"
            aria-hidden="false"
            aria-label="Lista com os cinco passos necessários para realizar a contratação do crédito imobiliário"
          >
            {steps.map((feature, index) => (
              <li key={index}>
                <Icon
                  icon={feature.icon}
                  className="homeInsuranceSteps__icon"
                  aria-hidden
                />
                {feature.richText}
              </li>
            ))}
          </ul>
          <Button
            className="homeInsuranceSteps__button"
            tagName="a"
            onClick={trackClick}
            href="https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/seguro-residencial"
            target="_blank"
            rel="noreferrer"
          >
            conheça todos os benefícios
          </Button>
        </div>
      </div>
    </section>
  )
}

export default HomeInsurance
