import { useEffect } from "react"

import { navigate } from "gatsby"

import {
  addTrackPageViewOnScroll,
  trackEvent
} from "@garantidos/utils/tracking"

import linksEnum from "pages/Home/constants/linksEnum"

const useEasines = () => {
  const trackingInfo = {
    pageview: {
      ga: { label: "IT:NL:NCC:CredImob:Inicio:FacilidadesParaContratar" }
    },
    event: {
      ga: { label: "BTN:Inicio:IniciarSimulacao1", action: "Inicio" }
    }
  }

  useEffect(() => {
    addTrackPageViewOnScroll({
      elementId: "#easines",
      trackObject: trackingInfo.pageview,
      threshold: 0.5
    })
  }, [])

  const trackClick = (event) => {
    event.preventDefault()
    trackEvent(trackingInfo.event)
    const urlParams = window.location.search
    navigate(`${linksEnum.PROPOSAL_PATHNAME}${urlParams}`)
  }
  return { trackClick }
}

export default useEasines
