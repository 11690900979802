import If from "@mobi/components/If"
import Button from "@mobi/ds/components/Button"

import Img from "components/img"

import CardBig from "pages/Home/components/common/CardBig"

import useBeAClient from "./hooks"

import "./style.scss"

const BeAClient = () => {
  const { trackClick, isMobile } = useBeAClient()

  return (
    <section className="beAClient" id="be-a-client">
      <div className="beAClient__container">
        <div className="beAClient__infoWrapper">
          <div className="beAClient__cardContainer">
            <CardBig
              icon="consorcio-imobiliario"
              title="seja cliente Itaú"
              content="Gostou do Crédito Imobiliário e ainda não é correntista Itaú? Abra já a sua conta e aproveite as melhores taxas do mercado."
            />
          </div>

          <If
            condition={isMobile}
            renderIf={
              <div className="beAClient__picture">
                <Img
                  file="lp-imobi-5"
                  alt="Homem segurando chaves"
                  className="beAClient__image"
                  data-aos="fade-left"
                />
              </div>
            }
          />

          <div className="beAClient__buttonContainer">
            <Button
              className="beAClient__button"
              tagName="a"
              href="https://www.itau.com.br/contas/conta-corrente/?utm_source=cross-crediario&utm_context=consignado&utm_camp=vitrine-crediario&utm_med=cross-sell&utm_sou=consignado&utm_term=imob-x-lp-card&utm_sou=imob&utm_med=x&utm_camp=lp&utm_cont=card&utm_ter=47"
              target="_blank"
              rel="noreferrer"
              onClick={trackClick}
              data-aos="fade-in"
              data-aos-delay="250"
            >
              abra a sua conta
            </Button>
          </div>
        </div>

        <If
          condition={!isMobile}
          renderIf={
            <div className="beAClient__imageContainer">
              <Img
                file="lp-imobi-5"
                alt="Homem segurando chaves"
                className="beAClient__image"
                data-aos="fade-left"
              />
            </div>
          }
        />
      </div>
    </section>
  )
}

export default BeAClient
